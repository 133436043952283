import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'loginPage',
    component: () => import('@/views/login/index')
  },
  {
    path: '/',
    name: 'layout',
    redirect: '/workbench',
    component: () => import('@/views/layout/index'),
    children: [
      {         // 工作台
        path: '/workbench',
        name: 'workbenchPage',

        component: () => import('@/views/pages/workbench/index')
      },
      // {    // 核销管理
      //   path: '/writeOff',
      //   name: 'writeOff',
      //   component: () => import('@/views/pages/writeOff/index')
      // },  
      {  // 美容洗车
        path: '/beautyCar',
        name: 'beautyCar',
        component: () => import('@/views/pages/beautyCar/index')
      },
      {  // 快修快保
        path: '/quickRepair',
        name: 'quickRepair',
        component: () => import('@/views/pages/quickRepair/index')
      },
      {  // 年检审车
        path: '/vehicleReview',
        name: 'vehicleReview',
        component: () => import('@/views/pages/vehicleReview/index')
      },
      {  // 应急救援
        path: '/emergencyRescue',
        name: 'emergencyRescue',
        component: () => import('@/views/pages/emergencyRescue/index')
      },
      {  // 自助洗车服务
        path: '/auto',
        name: 'auto',
        component: () => import('@/views/pages/auto/index.vue')
      },
      {  // 故障维修
        path: '/breakdownMaintenance',
        name: 'breakdownMaintenance',
        component: () => import('@/views/pages/breakdownMaintenance/index')
      },
      {  // 服务商品
        path: '/serviceGoods',
        name: 'serviceGoods',
        component: () => import('@/views/pages/serviceGoods/index')
      },
      {  // 会员管理
        path: '/membershipManagement',
        name: 'membershipManagement',
        component: () => import('@/views/pages/membershipManagement/index')
      },
      {  // 新增工单
        path: '/addGongdan',
        name: 'addGongdan',
        component: () => import('@/views/pages/addGongdan/index')
      },
      {  // 全部工单
        path: '/allOrders',
        name: 'allOrders',
        component: () => import('@/views/pages/allOrders/index')
      },
      {  // 团队核销
        path: '/tuanGou',
        name: 'tuanGou',
        component: () => import('@/views/pages/tuanGou/index')
      },
      {  // 团队详情
        path: '/tuanGouDetail',
        name: 'tuanGouDetail',
        component: () => import('@/views/a-routers/tuanGouDetail.vue')
      },
      // routerPage
      {  // 充值消费记录
        path: '/consumptionPage',
        name: 'consumptionPage',
        component: () => import('@/views/a-routers/consumptionPage')
      },
      {  // 积分记录
        path: '/integralPage',
        name: 'integralPage',
        component: () => import('@/views/a-routers/integralPage')
      },
      {  // 会员详情
        path: '/memberDetail',
        name: 'memberDetail',
        component: () => import('@/views/a-routers/memberDetail')
      },
      {  // 订单详情
        path: '/orderDetail',
        name: 'orderDetail',
        component: () => import('@/views/a-routers/orderDetail')
      },
      {  //添加套餐
        path: '/addTc',
        name: 'addTc',
        component: () => import('@/views/a-routers/addTc')
      },
      {  // 异常处理
        path: '/exceptionHandling',
        name: 'exceptionHandling',
        component: () => import('@/views/a-routers/exceptionHandling')
      },
    ]
  }


]
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}
const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
  const token = store.state.user.token

  if (to.path !== '/login' && !token) { // 没有token进入登录页
    next('/login')
  } else if (to.path === '/login' && token) {
    next('/')
  } else {
    next()
  }


  // 在跳转前执行逻辑
})


router.afterEach(() => {
  // 在跳转后执行逻辑
  // console.log('已完成路由跳转');
});

export default router
